export default class SlickVehicleListingsGallery {
    public static init(extraImagesNumber:number) {
 const listings = $('.l-node-used-vehicle--search-result');

      $(listings).each(function(key, item) {

      const thumbnails = item.getElementsByClassName('slick--vehicle-results-thumbnails');
      const gallery = item.getElementsByClassName('slick--vehicle-results-gallery');
      if (thumbnails.length == 1 && gallery.length ==1 ) {

        let thumbnailElement = thumbnails[0];
        let galleryElement = gallery[0];
       

          let sliderIdName = 'slick--vehicle-results-gallery' + key;
          let sliderNavIdName = 'slick--vehicle-results-thumbnails' + key;
    
          galleryElement.id = sliderIdName;
          

          thumbnailElement.id = sliderNavIdName;
        
  
        var sliderId = '#' + sliderIdName;
        var sliderNavId = '#' + sliderNavIdName;

        $(sliderId).slick({
          slidesToScroll: 1,
          arrows: false,
          asNavFor: sliderNavId,
          autoplay: false
        });
        $(sliderNavId).slick({
          slidesToShow: extraImagesNumber,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: "<span class='slick-prev thumbs'>&lt;</span>",
          nextArrow: "<span class='slick-next thumbs'>&gt;</span>",
          asNavFor: sliderId,
          focusOnSelect: true,
          autoplay: false
        });
        $(sliderNavId + ' .slick-slide').mouseover(function(){
          $(this).click();
        });
      }
    });
  }
  }
