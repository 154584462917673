import L, { LatLngBounds, LatLngExpression, LatLngBoundsLiteral, LatLngTuple } from "leaflet";
import leafletGestureHandling from "leaflet-gesture-handling";
import { Page } from "static/js/app/modules/page";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import { HugoModuleMapConfig } from "themes/module_map/static/js/mapConfig";


export class Map {
    public static init(mapElId: string, mainPin: Pin, extraPins?: Pin[]) {
        Page.ready(() => {
            // Map API token
            const mapKey = "pk.cddd7c6f2cb56f4c00c2023703d6e6b4";
            
            if (!document.getElementById(mapElId) || mainPin.coords.isValid() == false) {
                return;
            }

            // Add layers that we need to the map
            // @ts-ignore
            const streets = L.tileLayer.Unwired({ key: mapKey, scheme: "streets" });

            const midPoint = mainPin.coords.getCenter();

            L.Map.addInitHook("addHandler", "gestureHandling", leafletGestureHandling);

            // Initialize the map
            const map = L.map(mapElId, {
                center: midPoint, //map loads with this location as center
                zoom: 15,
                layers: [streets], // Show 'streets' by default
                // @ts-ignore: no mapping file exist (being a bit naughty - OT)
                gestureHandling: true,
                gestureHandlingOptions: {
                    duration: 3000 
                }
            });

            // Add the 'scale' control
            L.control.scale().addTo(map);

            // Add a pin with the main branch name on it to the map
            const marker = L.marker(midPoint).addTo(map);
            marker.bindTooltip(mainPin.title).openTooltip();

            // add all other branches to the map
            var extraMarkers: L.Marker[] = [];
            
            if(extraPins != null)  {
                extraPins.forEach(pin => {
                    const pinMidPoint = pin.coords.getCenter();
                    const pinMarker = L.marker(pinMidPoint).addTo(map);
                    pinMarker.bindTooltip(pin.title).openTooltip();

                    extraMarkers.push(pinMarker);
                })
            }

            // Fit map to center of bounds
            var allMarkers = extraMarkers.concat(marker);

            var group: L.FeatureGroup = L.featureGroup(allMarkers);

            map.fitBounds(group.getBounds(), {
                padding: [50, 50],
                maxZoom: 15
            });

        })
    }

    public static initializeBranchesMap(config: HugoModuleMapConfig, mapElId: string, branches: DealerBranchPublicInfo[]) {
        if(!config?.map_branches?.length && !branches?.length) {
            return;
        }

        let validBranches = 
            (config?.map_branches?.length) 
                ? branches.filter(branch => config.map_branches.indexOf(Number(branch.id)) != -1)
                : branches; // default to show all branches when no "map_branches" are specified
                
        if(validBranches.length == 0) {
            return;
        }

        const mainPin = validBranches[0].getMapPin();
        const extraPins = validBranches
            .map(branch => branch.getMapPin());

        if (mainPin != null) {
            Map.init(mapElId, mainPin, extraPins);
        }
    }

    public static initializeDataBackedMaps(selector: string) {
        const maps = document.querySelectorAll<HTMLElement>(selector);

        maps.forEach(map => {
            if(map?.id == null) {
                console.error(`map for selector '${selector}' must have a unique id`)
            }

            const pinsJsonEl = document.getElementById(`${map.id}_data`);

            if(pinsJsonEl == null) {
                console.error(`map with id '${map.id}' has no data backing - add a script with type "application/json" element containing json with id '${map.id}_data' to the document`);
            }
            
            const pinsJson = pinsJsonEl.textContent;

            if(pinsJson == null || pinsJson.length == 0) {
                return;
            }

            const pins: Pin[] = 
                JSON.parse(pinsJson)
                    .map(
                        (p: { coords: L.LatLngLiteral | L.LatLngTuple; title: string; }) => 
                        new Pin(new LatLngBounds(p.coords, p.coords), p.title)
                    );

            const mainPin = pins.shift();
        
            Map.init(map.id, mainPin, pins);
        });
    }
}

export class Pin
{
    constructor(
        public coords: LatLngBounds,
        public title?: string
    ) {}
}